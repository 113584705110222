import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Margin from 'gatsby-theme-signalwerk/src/components/Margin';
import Grid from 'gatsby-theme-signalwerk/src/components/Grid';
import Column from 'gatsby-theme-signalwerk/src/components/Column';
import Box from 'gatsby-theme-signalwerk/src/components/Box';
export const _frontmatter = {
  "title": "Wie Co-Creation Unternehmen und KundInnen näher zusammenbringt",
  "author": "Wolfgang Schoeck",
  "date": "2020-01-11",
  "layout": "post",
  "draft": false,
  "hideInMenu": false,
  "path": "/posts/2020/wolfgang/",
  "tags": ["TagOne", "TagTwo"],
  "description": "Durch das Internet ergeben sich für Unternehmen und KundInnen neue Möglichkeiten, eng miteinander zu kooperieren. Eine Co-Creation bietet dabei verschiedenste Ansätze für eine gemeinsame Zusammenarbeit. Dadurch verändert sich aber die Rollenverteilung zwischen Firmen und Ihren KundInnen sowie der Umgang mit Produkten ganz allgemein."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "inhaltsverzeichnis"
    }}>{`Inhaltsverzeichnis`}</h2>
    <div {...{
      "className": "toc"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#einleitung"
          }}>{`Einleitung`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#unternehmen-und-kundinnen--fr%C3%BCher-und-heute"
            }}>{`Unternehmen und KundInnen – früher und heute`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#die-direkte-zusammenarbeit-in-einer-co-creation"
          }}>{`Die direkte Zusammenarbeit in einer Co-Creation`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#%C3%BCber-co-creation"
            }}>{`Über Co-Creation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#das-dart-modell"
            }}>{`Das DART-Modell`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#die-firma-local-motors"
            }}>{`Die Firma `}<em parentName="a">{`Local Motors`}</em></a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#die-neue-rolle-der-konsumentinnen-innerhalb-des-marktes"
            }}>{`Die neue Rolle der KonsumentInnen innerhalb des Marktes`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#wie-firmen-anhand-von-co-creation-innovation-entwickeln"
            }}>{`Wie Firmen anhand von Co-Creation Innovation entwickeln`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#wie-kundinnen-von-einer-co-creation-profitieren-k%C3%B6nnen"
            }}>{`Wie KundInnen von einer Co-Creation profitieren können`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#offenheit-als-strategie--dialogbereitschaft-und-transparenz"
            }}>{`Offenheit als Strategie – Dialogbereitschaft und Transparenz`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#das-erlebnis-als-zentraler-wert-in-einer-co-creation"
          }}>{`Das Erlebnis als zentraler Wert in einer Co-Creation`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#der-wert-von-produkten"
            }}>{`Der Wert von Produkten`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#das-personalisierte-nutzererlebnis"
            }}>{`Das personalisierte Nutzererlebnis`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mass-customization-in-abgrenzung-zu-co-creation"
            }}><em parentName="a">{`Mass Customization`}</em>{` in Abgrenzung zu Co-Creation`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#die-gewichtung-von-partnerschaften"
            }}>{`Die Gewichtung von Partnerschaften`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#relevante-inhalte-durch-strategische-partnerschaften"
            }}>{`Relevante Inhalte durch strategische Partnerschaften`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#der-austausch-mit-der-community"
            }}>{`Der Austausch mit der `}<em parentName="a">{`Community`}</em></a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#chancen-und-risiken-der-co-creation"
          }}>{`Chancen und Risiken der Co-Creation`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#das-abw%C3%A4gen-zwischen-vor--und-nachteilen"
            }}>{`Das Abwägen zwischen Vor- und Nachteilen`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#die-community-erh%C3%A4lt-mehr-gewicht"
            }}>{`Die `}<em parentName="a">{`Community`}</em>{` erhält mehr Gewicht`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#die-demokratisierung-von-entscheidungen"
            }}>{`Die Demokratisierung von Entscheidungen`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#fazit"
          }}>{`Fazit`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#anhang"
          }}>{`Anhang`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#endnoten"
            }}>{`Endnoten`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#literaturverzeichnis"
            }}>{`Literaturverzeichnis`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#quellenverzeichnis"
            }}>{`Quellenverzeichnis`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#abbildungsverzeichnis"
            }}>{`Abbildungsverzeichnis`}</a></li>
        </ul>
      </li>
    </ul>
    <undefined></undefined>
    <h2 {...{
      "id": "einleitung"
    }}>{`Einleitung`}</h2>
    <h3 {...{
      "id": "unternehmen-und-kundinnen--früher-und-heute"
    }}>{`Unternehmen und KundInnen – früher und heute`}</h3>
    <p>{`In der ersten Hälfte des 19. Jahrhunderts zeichnete sich der Laden um die Ecke über Kundennähe aus, die Ladenbesitzer kannten ihre Kundschaft und konnten diese individuell beraten und bedienen. Durch die Form des persönlichen Austauschs hatten die Läden eine entscheidende gesellschaftliche Funktion inne, indem sie Lokalnachrichten verbreiteten, bevor dies Radio und Fernsehen übernahmen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-1"
      }}><a parentName="sup" {...{
          "href": "#ref-1",
          "className": "footnote-inline-link"
        }}>{`1`}</a></sup>{` Das Einkaufserlebnis war folglich also ein ganz persönliches und durch Interaktion mit den VerkäuferInnen geprägt, zudem wurden zwischen diesen beiden Parteien auch eine Vielzahl an nützlichen Informationen, sowohl für die KundInnen als auch für die VerkäuferInnen ausgetauscht.`}</p>
    <p>{`Seither hat sich das Einkaufserlebnis mehrfach stark gewandelt, Warenhäuser wie das `}<em parentName="p">{`Selfridges`}</em>{` in London boten Kunden neben verschiedenen Warenabteilungen auch Restaurants, ein Erste-Hilfe-Zimmer oder sogar einen Dachgarten an und versuchten sich damals vor allem über Service auszuzeichnen. Harry Selfridge prägte im Zuge dessen die Formel «Der Kunde hat immer Recht».`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-2"
      }}><a parentName="sup" {...{
          "href": "#ref-2",
          "className": "footnote-inline-link"
        }}>{`2`}</a></sup>{` Und auch die daraufhin entstehenden Einkaufszentren versuchten den KundInnen ein möglichst bequemes Einkaufserlebnis zu ermöglichen, indem dass mehrere Geschäfte an einem Ort erreichbar gemacht und diese durch Gastronomiebereiche und Kinos miteinander verbunden wurden.`}</p>
    <p>{`Als daraufhin zwischenzeitlich «der Kunde ist König» zum alles bestimmenden Leitsatz erhoben wurde und riesige Supermärkte versuchten, sich vor allem über Multipackungen, Rabatte und dem damit verbundenen niedrigen Preis zu profilieren, kamen in den 1980er Jahren wieder vermehrt Fachgeschäfte auf, die sich auf spezifische Marken und exklusive Produkte konzentrierten und versuchten, den KundInnen im Geschäft selbst ein möglichst besonderes Einkaufserlebnis zu ermöglichen.`}</p>
    <p>{`Heute wird ein grosser Teil der Produkte direkt über das Internet bezogen und dadurch bietet sich für Unternehmen und KundInnen die Möglichkeit, wieder direkt und unmittelbar miteinander zu interagieren, wodurch sich auch die Beziehung zwischen den beiden erneut verändert.`}</p>
    <p>{`Bezeichnend für diese Veränderung ist das Beispiel `}<em parentName="p">{`Napster`}</em>{`, eine `}<em parentName="p">{`Peer-to-Peer`}</em>{` Musiktauschbörse bei welcher über das Internet auf unzählige Musikstücke zugegriffen werden konnte und die nach rechtlichen Streitigkeiten 2001 geschlossen werden musste.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-3"
      }}><a parentName="sup" {...{
          "href": "#ref-3",
          "className": "footnote-inline-link"
        }}>{`3`}</a></sup>{` Die grosse Beliebtheit der Plattform machte deutlich, dass KonsumentInnen zwar ein überaus grosses Interesse an den Produkten der Musikindustrie haben, auf die Musik aber lieber auf Grund ihrer eigenen Vorlieben und nicht anhand von vordefinierten, durch Plattenfirmen zusammengestellten Produkten wie Alben oder Sampler zugreifen möchten.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-4"
      }}><a parentName="sup" {...{
          "href": "#ref-4",
          "className": "footnote-inline-link"
        }}>{`4`}</a></sup></p>
    <p>{`Dieses Beispiel zeigt exemplarisch auf, wie durch das Internet der unmittelbare Zugang zu einem breiten Angebot ermöglicht wird und wie sich dadurch auch die Beziehung der KonsumentInnen zu einem Produkt gewandelt hat. Gleichzeitig bietet das Internet aber auch die Möglichkeit, dass sich Unternehmen und KundInnen einander annähern können und dabei wird vermehrt auf eine Kollaboration in Form einer Co-Creation gesetzt.`}</p>
    <p>{`Da KundInnen und Firmen nun in unmittelbarem Kontakt zueinander stehen, können diese direkt miteinander interagieren. Ziel der folgenden Arbeit ist es, die verschiedenen Formen der Zusammenarbeit aufzuzeigen, die durch eine Co-Creation ermöglicht werden und zu ergründen, inwiefern sich diese Kooperation auf die Beziehung zwischen Firmen und KundInnen auswirkt. Anschliessend soll auf die Veränderungen in Bezug auf das Angebot und die damit verbundene Definition von Wertschöpfung sowie auf Chancen und Risiken in Zusammenhang mit einer Co-Creation eingegangen werden.`}</p>
    <h2 {...{
      "id": "die-direkte-zusammenarbeit-in-einer-co-creation"
    }}>{`Die direkte Zusammenarbeit in einer Co-Creation`}</h2>
    <h3 {...{
      "id": "über-co-creation"
    }}>{`Über Co-Creation`}</h3>
    <p>{`Der Begriff Co-Creation wurde von C. K. Prahalad und Venkat Ramaswamy in ihrem 2004 veröffentlichten Buch `}<em parentName="p">{`The Future of Competition`}</em>{` geprägt. Darin wird Co-Creation als eine Zusammenarbeit zwischen Firmen und KundInnen beschrieben, bei der in enger Kooperation gemeinsam Produkte entwickelt werden.`}</p>
    <p>{`Gemäss Prahalad und Ramaswamy (2004) lassen sich vier Hauptaspekte definieren, die bei einer Co-Creation beachtet werden müssen. Das sogenannte DART-Modell (`}<em parentName="p">{`Dialog, Access, Risk Assessment, Transparency`}</em>{`) besagt, dass in einer Co-Creation zwischen Unternehmen und KundInnen der Fokus hauptsächlich auf den Dialog, den Zugang, die Risikobeurteilung sowie die Transparenz gelegt werden sollte. Dabei definieren Prahalad und Ramaswamy die Begriffe wie folgt.`}</p>
    <h3 {...{
      "id": "das-dart-modell"
    }}>{`Das DART-Modell`}</h3>
    <p><strong parentName="p"><em parentName="strong">{`Dialog:`}</em></strong>{` Zwischen einer Firma und den KundInnen wird ein enger Austausch gepflegt. Dabei sollten sich beide auf Augenhöhe begegnen, sich intensiv miteinander auseinandersetzen und bereit sein, voneinander zu lernen.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Access:`}</em></strong>{` Ein direkter Zugang sollte gewährleistet werden, etwa zu einer Plattform, um sich mit Gleichgesinnten auszutauschen oder aber auch zu Experten, um von deren Fachwissen zu profitieren. Dabei sollten Werkzeugen bereitgestellt werden, die eine individuelle Nutzung eines Angebots ermöglichen.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Risk assessment:`}</em></strong>{` Das eigenverantwortliche Abwägen zwischen Vor- und Nachteilen gewinnt an Bedeutung. Das Risiko in Bezug auf ein Produkt, sollte sowohl von den Unternehmen als auch den KundInnen selbst eingeschätzt werden können.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`Transparency:`}</em></strong>{` Um Rückschlüsse, z. B. auf Firmenabläufe oder auf das persönliche Verhalten, zuzulassen, sollten Informationen frei verfügbar sein und offen mit diesen umgegangen werden.`}</p>
    <p>{`Werden die Punkte aus dem DART-Modell berücksichtigt, gehen Prahalad und Ramaswamy davon aus, dass sich eine für beide Seiten interessante Zusammenarbeit ergeben kann. Beispielhaft für eine Kollaboration im Stile einer Co-Creation ist die Firma `}<em parentName="p">{`Local Motors`}</em>{`, die im Folgenden vorgestellt werden soll.`}</p>
    <h3 {...{
      "id": "die-firma-local-motors"
    }}>{`Die Firma Local Motors`}</h3>
    <p><em parentName="p">{`Local Motors`}</em>{` ist ein Autohersteller aus den USA, der in einem engen Austausch mit einer `}<em parentName="p">{`Community`}</em>{` aus DesignerInnen, IngenieurInnen und Autofans Fahrzeuge über das Internet entwickelt.`}</p>
    <p>{`Die `}<em parentName="p">{`Community`}</em>{`-Mitglieder sind dabei in einzelne Kategorien eingeteilt, z. B. `}<em parentName="p">{`Builder, Designer, Visitor`}</em>{` etc. und je nach Registrierung können diese auf einer extra darauf ausgerichteten Online-Plattform Entwürfe für Fahrzeuge hochladen und so anhand von regelmässig stattfindenden Wettbewerben das Design oder auch das Innenleben von diesen mitgestalten. Dabei legt `}<em parentName="p">{`Local Motors`}</em>{` viel Wert auf gegenseitiges Feedback, so geben sich die TeilnehmerInnen während des Wettbewerbs wechselseitig Rückmeldungen zu den kontinuierlich auf der Website veröffentlichten Vorschlägen.`}</p>
    <p>{`Über ein `}<em parentName="p">{`Crowd-Voting`}</em>{` wird anschliessend in der `}<em parentName="p">{`Community`}</em>{` darüber abgestimmt, welche Vorschläge in Produktion gehen sollen. Wird ein Fahrzeug von einem Kunden oder einer Kundin  geordert, liefern Partnerfirmen die Komponenten an sogenannte `}<em parentName="p">{`Microfactories`}</em>{`, kleine Werkhallen, in denen die Autos dann von `}<em parentName="p">{`Local Motors`}</em>{`-MechanikerInnen sowie den KundInnen selbst zusammengebaut werden.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-5"
      }}><a parentName="sup" {...{
          "href": "#ref-5",
          "className": "footnote-inline-link"
        }}>{`5`}</a></sup></p>
    <p>{`Diese Form der direkten Beteiligung sowohl von KonsumentInnen als auch von Partnerfirmen an einem Produkt ist bezeichnend für eine Co-Creation. Anschliessend soll nun beleuchtet werden, inwiefern sich die Rolle der KonsumentInnen durch deren aktive Beteiligung verändert.`}</p>
    <h3 {...{
      "id": "die-neue-rolle-der-konsumentinnen-innerhalb-des-marktes"
    }}>{`Die neue Rolle der KonsumentInnen innerhalb des Marktes`}</h3>
    <p>{`Die heutigen KonsumentInnen sind gemäss Prahalad und Ramaswamy (2004) informiert, vernetzt und gestalten ihren Konsum aktiv mit, wodurch sich auch ihre Position innerhalb eines Marktes grundlegend verändert. Während sie früher innerhalb des Marktes isoliert und auf die Rolle der KonsumentInnen reduziert wurden, verfügen sie nun dank dem Internet über eine Vielzahl an Informationen und Tools, die es ihnen ermöglichen, sich direkt zu beteiligen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-6"
      }}><a parentName="sup" {...{
          "href": "#ref-6",
          "className": "footnote-inline-link"
        }}>{`6`}</a></sup>{` Aufgrund der wachsenden Unzufriedenheit mit einer von Firmen vordefinierten Auswahl, kombiniert mit den Möglichkeiten des Internets, bringen KonsumentInnen ihre Wünsche und Bedürfnisse also vermehrt in einen Wertschöpfungsprozess mit ein`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-7"
      }}><a parentName="sup" {...{
          "href": "#ref-7",
          "className": "footnote-inline-link"
        }}>{`7`}</a></sup>{` und begeben sich dabei mit Firmen in einen engen Austausch. Prahalad und Ramaswamy empfehlen daher, den Markt primär als ein Forum zu betrachten, in dem sich die beiden Rollenbilder Konsument und Produzent miteinander vermischen. `}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-8"
      }}><a parentName="sup" {...{
          "href": "#ref-8",
          "className": "footnote-inline-link"
        }}>{`8`}</a></sup></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3c31f2da93660742c3378bcd4c179705/1dd40/co-creation_3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAelTSJKj/8QAFxABAQEBAAAAAAAAAAAAAAAAAgEDEP/aAAgBAQABBQK7mN7QTNzQ9//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAQADAQEAAAAAAAAAAAAAAAEAERIgMf/aAAgBAQAGPwLOX2paMsOP/8QAGBAAAwEBAAAAAAAAAAAAAAAAAREhACD/2gAIAQEAAT8hP7xQDMRVTPMAavH/2gAMAwEAAgADAAAAEOPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAwADAQAAAAAAAAAAAAAAAAERITFBUf/aAAgBAQABPxBpWqQGm/d3oopDhJkUyJuCii0oU//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Co-Creation",
            "title": "Co-Creation",
            "src": "/static/3c31f2da93660742c3378bcd4c179705/3d0fe/co-creation_3.jpg",
            "srcSet": ["/static/3c31f2da93660742c3378bcd4c179705/90185/co-creation_3.jpg 450w", "/static/3c31f2da93660742c3378bcd4c179705/7288a/co-creation_3.jpg 900w", "/static/3c31f2da93660742c3378bcd4c179705/3d0fe/co-creation_3.jpg 1800w", "/static/3c31f2da93660742c3378bcd4c179705/1dd40/co-creation_3.jpg 2400w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Die Interaktion zwischen KonsumentInnen und Firmen ist auf den Austausch von Produkten reduziert.`}<sup parentName="em" {...{
          "className": "footnote-inline",
          "id": "use-ref-fig-1"
        }}><a parentName="sup" {...{
            "href": "#ref-fig-1",
            "className": "footnote-inline-link"
          }}>{`Abb. 1`}</a></sup></em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2a1018162597036e615ebd5e2b084e59/1dd40/co-creation_2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB3IXKGBX/xAAZEAACAwEAAAAAAAAAAAAAAAABEAAREiH/2gAIAQEAAQUCPJoul//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAQACAwAAAAAAAAAAAAAAAAEAEBEhMf/aAAgBAQABPyF4aIUdgCv/2gAMAwEAAgADAAAAEIvP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQEAAgMAAAAAAAAAAAAAAREAECFBUYH/2gAIAQEAAT8Qheb0aposZ0cMESmQsH5rv//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Co-Creation",
            "title": "Co-Creation",
            "src": "/static/2a1018162597036e615ebd5e2b084e59/3d0fe/co-creation_2.jpg",
            "srcSet": ["/static/2a1018162597036e615ebd5e2b084e59/90185/co-creation_2.jpg 450w", "/static/2a1018162597036e615ebd5e2b084e59/7288a/co-creation_2.jpg 900w", "/static/2a1018162597036e615ebd5e2b084e59/3d0fe/co-creation_2.jpg 1800w", "/static/2a1018162597036e615ebd5e2b084e59/1dd40/co-creation_2.jpg 2400w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Der Markt wird als Forum betrachtet, in dem sich KundInnen und Firmen austauschen.`}<sup parentName="em" {...{
          "className": "footnote-inline",
          "id": "use-ref-fig-2"
        }}><a parentName="sup" {...{
            "href": "#ref-fig-2",
            "className": "footnote-inline-link"
          }}>{`Abb. 2`}</a></sup></em></p>
    <p>{`Dabei verschwindet die klare Trennung innerhalb des Wertschöpfungsprozesses zwischen ProduzentInnen und KonsumentInnen. Der Futurologe Alvin Toffler hat dafür den Begriff «Prosumenten» geprägt, der benennt, dass KonsumentInnen nun also auch gleichzeitig ProduzentInnen sind.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-9"
      }}><a parentName="sup" {...{
          "href": "#ref-9",
          "className": "footnote-inline-link"
        }}>{`9`}</a></sup></p>
    <p>{`Unterstützt durch das Internet verändert sich also die Rolle der KonsumentInnen. Während früher die Interaktion zwischen einer Firma und den KundInnen oftmals nur auf den Austausch von Produkten reduziert war, wird heute ein direkter und kontinuierlicher Dialog geführt. Dadurch werden auch Produktentwicklungen ganz massgeblich beeinflusst. Anschliessend soll erst aus Firmen- und dann aus KundInnenperspektive betrachtet werden, welche Auswirkungen der direkte Austausch auf die Entwicklung von Produkten hat und inwiefern beide Seiten von einer engen Kooperation profitieren können.`}</p>
    <h3 {...{
      "id": "wie-firmen-anhand-von-co-creation-innovation-entwickeln"
    }}>{`Wie Firmen anhand von Co-Creation Innovation entwickeln`}</h3>
    <p>{`ManagerInnen fokussierten sich früher primär auf intern entwickelte Innovationen, um einen Wettbewerbsvorteil zu erhalten. Dabei wurden die Produkte auf Grund von Marktforschungen oder in punktueller Zusammenarbeit mit sogenannten `}<em parentName="p">{`Lead Usern`}</em>{` (KundInnen welche sich aufgrund ihrer aussergewöhnlichen Merkmalen von anderen abheben) entwickelt und diese darauf dann an den Märkten den KonsumentInnen angeboten.`}</p>
    <p>{`Nun werden die NutzerInnen bereits bei der Entwicklung eines Produktes aktiv mit einbezogen. Auf Grund der vereinfachten Mobilisierung einer grossen Anzahl an Menschen über das Internet ist es möglich, in kurzer Zeit, eine Vielzahl an Rückmeldungen zu generieren – dabei ersetzen kontinuierliche Feedback-Schleifen den bisher linear vordefinierten Ablauf einer Produktentwicklung, wodurch Firmen die Entwicklungszeit drastisch verkürzen sowie ihre Kosten reduzieren können.`}</p>
    <p>{`Auch bei `}<em parentName="p">{`Local Motors`}</em>{` wird ein Fahrzeug nicht intern entwickelt und im Anschluss daran dann ein fertiges Produkt auf den Markt gebracht, vielmehr werden die KonsumentInnen bereits von Anfang an in der sogenannten `}<em parentName="p">{`Create it`}</em>{`-Phase anhand von Wettbewerben direkt mit eingebunden. Dadurch sind die Entwicklungskosten für die Firma erheblich geringer. Zum Vergleich: Während die Entwicklungszeit des Modells `}<em parentName="p">{`Rally Fighter`}</em>{` bei `}<em parentName="p">{`Local Motors`}</em>{` gerade mal 18 Monate dauerte und 3,6 Millionen Dollar gekostet hat, liegt der Zeitraum für eine Produktlancierung in der klassischen Autoindustrie bei 5 bis 7 Jahren und die Kosten dafür können sich auch gut einmal auf 1,5 Milliarden belaufen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-10"
      }}><a parentName="sup" {...{
          "href": "#ref-10",
          "className": "footnote-inline-link"
        }}>{`10`}</a></sup></p>
    <p>{`Zudem führt der frühe Einbezug der NutzerInnen in einen Entwicklungsprozess auch dazu, dass Firmen zielgerichteter produzieren können. Dank dem direkten Feedback (quasi in Echtzeit), kennen Firmen ihre KundInnen heute besser denn je und können dadurch ihre Strategie gezielter anpassen.`}</p>
    <p><em parentName="p">{`Local Motors`}</em>{` setzt dafür ihre Online-Plattform ein, auf der sich die `}<em parentName="p">{`Community`}</em>{` über aktuelle Projekte austauschen kann. So wird einerseits die Innovationskraft der `}<em parentName="p">{`Crowd`}</em>{` genutzt, indem auf die Ideen und Anregungen einer Vielzahl von Menschen zurückgegriffen werden kann, zusätzlich gewinnt `}<em parentName="p">{`Local Motors`}</em>{` über den direkten Austausch mit den KundInnen aber auch Einblicke in Bezug auf deren Bedürfnisse und Wünsche.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-11"
      }}><a parentName="sup" {...{
          "href": "#ref-11",
          "className": "footnote-inline-link"
        }}>{`11`}</a></sup></p>
    <p>{`Die unmittelbare Rückmeldung der KundInnen hat auch starken Einfluss darauf, wie Firmen ihre Prozesse organisieren. Während früher anhand von langwieriger Marktforschung Erkenntnisse über ein Produkt gewonnen wurden, können Firmen heute aufgrund der schnellen Rückmeldungen der KundInnen über das Internet in viel kürzeren Intervallen ihre Entwicklungen anpassen und bei Bedarf auch neu ausrichten.`}</p>
    <p>{`Folglich werden Innovationen heute nicht mehr im Alleingang durch Firmen entwickelt wodurch die bisherige firmenzentrierte Sicht immer mehr durch eine konsumentenzentrierte ersetzt wird. Die KundInnen rücken damit im Innovationsprozess zunehmend ins Zentrum.`}</p>
    <p>{`Dank dem unmittelbaren Austausch haben also auch die KonsumentInnen die Möglichkeit direkten Einfluss auf eine Produktentwicklung zu nehmen und Angebote in ihrem Sinne mit zu gestalten. Dieser Aspekt soll im Folgenden behandelt werden.`}</p>
    <h3 {...{
      "id": "wie-kundinnen-von-einer-co-creation-profitieren-können"
    }}>{`Wie KundInnen von einer Co-Creation profitieren können`}</h3>
    <p>{`Während Innovationen also früher hinter verschlossenen Türen vorangetrieben wurden, werden diese heute in einem engen Austausch mit den KundInnen entwickelt.`}</p>
    <p>{`Mittels Feedback, Umfragen, `}<em parentName="p">{`Crowd-Voting`}</em>{` oder Wettbewerben können diese dabei direkt an einer Produktentwicklung teilhaben. So dient die Online-Plattform von `}<em parentName="p">{`Local Motors`}</em>{` einerseits dazu, um mit den KundInnen in einen Dialog zu treten und dadurch ihre Bedürfnisse und Wünsche besser kennenzulernen, die Website kann aber zudem auch dazu genutzt werden, um Produktinnovationen von Anfang an und in enger Zusammenarbeit mit den KundInnen anhand von Wettbewerben zu entwickeln.`}</p>
    <p>{`Im Falle von `}<em parentName="p">{`Local Motors`}</em>{` wird dafür ein Gesamtprojekt in monatliche Einzelwettbewerbe unterteilt, welche über die Online-Plattform ausgeschrieben werden. Die Vorschläge der TeilnehmerInnen werden darauf in einer sogenannten `}<em parentName="p">{`Punch-List`}</em>{` veröffentlicht und dokumentiert, wobei sich die DesignerInnen gegenseitiges Feedback geben. Mittels `}<em parentName="p">{`Crowd-Voting`}</em>{` wird dann bestimmt, welches Design am besten ankommt und für das fertige Produkt zur Anwendung kommt.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-12"
      }}><a parentName="sup" {...{
          "href": "#ref-12",
          "className": "footnote-inline-link"
        }}>{`12`}</a></sup></p>
    <p>{`Da die KundInnen über die Plattform einen unmittelbaren Zugang zu der Firma erhalten und sich somit einfacher denn je mit einbringen können, ist es möglich, dass diese ihre Wertvorstellungen, Bedürfnisse und Wünsche direkt in eine Produktentwicklung mit einfliessen lassen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-13"
      }}><a parentName="sup" {...{
          "href": "#ref-13",
          "className": "footnote-inline-link"
        }}>{`13`}</a></sup></p>
    <p>{`Durch dieses Engagement erreichen die KundInnen, dass sich ein neues Angebot näher an ihren Bedürfnissen orientiert. Während früher darauf abgezielt wurde, ein Produkt anhand von Marketing-Massnahmen zu verkaufen, wird heute also vermehrt versucht, eine Nachfrage über die Passgenauigkeit eines Produkts in Bezug auf die Ansprüche der NutzerInnen zu schaffen.`}</p>
    <p>{`Durch den engen Einbezug der KundInnen wird die bisher übliche Unterteilung in eine interne Entwicklungsphase und den darauffolgenden Verkauf eines Produktes auf dem Markt, aufgehoben. Diese enge Zusammenarbeit setzt dabei aber einen offenen Dialog zwischen den Unternehmen und den KundInnen voraus, worauf hier im Folgenden eingegangen werden soll.`}</p>
    <h3 {...{
      "id": "offenheit-als-strategie--dialogbereitschaft-und-transparenz"
    }}>{`Offenheit als Strategie – Dialogbereitschaft und Transparenz`}</h3>
    <p>{`Während Firmen früher von einer gewissen Informationsasymmetrie durchaus auch profitieren konnten`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-14"
      }}><a parentName="sup" {...{
          "href": "#ref-14",
          "className": "footnote-inline-link"
        }}>{`14`}</a></sup>{` und z. B. die Zusammensetzung eines Produkts ein gut gehütetes Firmengeheimnis war, herrscht heute eine neue Form der Transparenz, etwa in Bezug auf Preise sowie Kosten und KonsumentInnen können sich über Produkte oder aber auch deren Herstellungsprozesse genau informieren.`}</p>
    <p>{`Wenn Folglich Information über Produkte einfach abgerufen werden können,`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-15"
      }}><a parentName="sup" {...{
          "href": "#ref-15",
          "className": "footnote-inline-link"
        }}>{`15`}</a></sup>{` sind die Firmen gut beraten, über ein transparentes Verhalten, Vertrauen zwischen sich und ihren KundInnen aufzubauen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-16"
      }}><a parentName="sup" {...{
          "href": "#ref-16",
          "className": "footnote-inline-link"
        }}>{`16`}</a></sup>{` Dabei geht es um weit mehr als Pressemitteilungen und wirksame Publicity, sondern darum, die KundInnen in einen engen und kontinuierlichen Dialog sowie in öffentliche Debatten mit einzubinden`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-17"
      }}><a parentName="sup" {...{
          "href": "#ref-17",
          "className": "footnote-inline-link"
        }}>{`17`}</a></sup>{` und diese dabei als gleichwertige Gesprächspartner zu betrachten.`}</p>
    <p>{`Gleichzeitig betrifft diese neue Transparenz aber auch die KonsumentInnen, in dem diese, z. B. im Gegenzug für den Zugriff auf Angebote, auch Informationen in Bezug auf ihr persönliches Verhalten oder ihre Wertvorstellungen offenlegen.`}</p>
    <p>{`Die enge Kollaboration zwischen Unternehmen und KundInnen verändert aber nicht nur deren Beziehung untereinander, auch der Umgang mit den Produkten selbst ist dank einem unmittelbaren Zugang zu diesen einem Wandel unterzogen, was im nächsten Abschnitt erläutert werden soll.`}</p>
    <h2 {...{
      "id": "das-erlebnis-als-zentraler-wert-in-einer-co-creation"
    }}>{`Das Erlebnis als zentraler Wert in einer Co-Creation`}</h2>
    <h3 {...{
      "id": "der-wert-von-produkten"
    }}>{`Der Wert von Produkten`}</h3>
    <p>{`Prahalad und Ramaswamy (2004) gehen davon aus, dass der effektive Besitz eines Produktes zunehmend an Bedeutung verliert und die KonsumentInnen in Zukunft vielmehr nach einem Zugang zu einem auf sie zugeschnittenen Erlebnis suchen. Während früher versucht wurde, über eine möglichst breite Produktpalette oder über eine Vielzahl an zusätzlichen Funktionen verschiedenste Kundengruppen für sich zu gewinnen, wird zunehmend das individuelle Erlebnis bei der Nutzung eines Angebots wichtiger.`}</p>
    <p>{`Dies wird am Beispiel einer Digitalkamera illustriert, bei der für Prahalad und Ramaswamy nicht primär deren Produktionsart oder Funktionalitäten relevant sind, sondern in  erster Linie das Erlebnis, welches durch die Kamera ermöglicht wird, z. B. in dem Bilder aus dem Urlaub mit anderen geteilt werden können.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-18"
      }}><a parentName="sup" {...{
          "href": "#ref-18",
          "className": "footnote-inline-link"
        }}>{`18`}</a></sup>{` Dafür müssen die KundInnen ein Produkt nicht zwangsläufig besitzen, oftmals besteht der Wert eines Angebots für sie bereits in dessen Nutzung.`}</p>
    <p>{`Da sich Wettbewerbsvorteile also nicht primär über den Preis, die Qualität sowie die Vielfalt an Funktionen erreichen lassen, empfehlen Prahalad und Ramaswamy, dass sich Firmen weg von einer produktzentrierten Sichtweise, hin zu einem Co-Creation-Erlebnis aus der Sicht der KonsumentInnen bewegen. Dabei ist es für sie nicht entscheidend, ob und durch welches Gerät dieses Erlebnis ermöglicht wird,`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-19"
      }}><a parentName="sup" {...{
          "href": "#ref-19",
          "className": "footnote-inline-link"
        }}>{`19`}</a></sup>{` Produkte und Services sind für sie nur ein Teil eines gesamthaften Erlebnisses.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-20"
      }}><a parentName="sup" {...{
          "href": "#ref-20",
          "className": "footnote-inline-link"
        }}>{`20`}</a></sup></p>
    <p>{`Haben sich Firmen in der Vergangenheit also darauf fokussiert, Produktinnovationen zu entwickeln, wird es zukünftig vermehrt darum gehen, ein Umfeld für ein entsprechendes Erlebnis bereitzustellen.`}</p>
    <p>{`Firmen sollten daher darum bemüht sein, den KonsumentInnen ein möglichst breites Angebot zu offerieren aus welchem sich diese dann ihr ganz persönliches und individuelles Produkt zusammenstellen können.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-21"
      }}><a parentName="sup" {...{
          "href": "#ref-21",
          "className": "footnote-inline-link"
        }}>{`21`}</a></sup>{` Im Folgenden soll auf diese verschiedenen Formen der Personalisierung eingegangen werden.`}</p>
    <h3 {...{
      "id": "das-personalisierte-nutzererlebnis"
    }}>{`Das personalisierte Nutzererlebnis`}</h3>
    <p>{`Die KundInnen haben über das Internet die Möglichkeit, Produkte auf einfache Weise zu verändern und diese ihren persönlichen Bedürfnissen anzupassen. In dem Zusammenhang spricht man von einem personalisierten Kundenerlebnis. Nicht selten legen Firmen daher ehemals interne, produktspezifische Daten zu einzelnen Komponenten offen, damit sich die KundInnen daraus ihr ganz individuelles Angebot zusammenstellen können.`}</p>
    <p>{`Bei `}<em parentName="p">{`Local Motors`}</em>{` haben IngenieurInnen und DesignerInnen der `}<em parentName="p">{`Community`}</em>{` in der sogenannten `}<em parentName="p">{`Develop it`}</em>{`-Phase direkten Zugang zu sämtlichen Bauteilen als CAD-Dateien, können diese bearbeiten und im Detail weiterentwickeln. Somit können theoretisch alle Bestandteile eines Fahrzeugs individuell verändert und angepasst werden.`}</p>
    <p>{`In der so genannten `}<em parentName="p">{`Mod it`}</em>{`-Phase lässt sich zudem ein Fahrzeug auch nach dem Kauf durch die KonsumentIn weiter modifizieren und anpassen. So stehen alle Bauteile der Fahrzeuge weiterhin als `}<em parentName="p">{`Open Source`}</em>{` der `}<em parentName="p">{`Community`}</em>{` zur Verfügung und können von dieser bearbeitet und anschliessend auch den anderen NutzerInnen wieder zur Verfügung gestellt werden.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-22"
      }}><a parentName="sup" {...{
          "href": "#ref-22",
          "className": "footnote-inline-link"
        }}>{`22`}</a></sup></p>
    <p>{`Anders als früher kaufen die KonsumentInnen also heute nicht ein abgeschlossenes Gesamtpaket, sondern stellen sich aus einer Vielzahl von Angeboten ihr ganz persönliches Produkt zusammen. Dabei besteht aber ein grundsätzlicher Unterschied zwischen einer Co-Creation und einer Individualisierung von Produkten, was im Anschluss erläutert werden soll.`}</p>
    <h3 {...{
      "id": "mass-customization-in-abgrenzung-zu-co-creation"
    }}>{`Mass Customization in Abgrenzung zu Co-Creation`}</h3>
    <p>{`Haben Firmen früher primär mit einer Diversifizierung des Angebots auf unterschiedlichen Kundenwünsche reagiert, wird heute auch versucht über eine sogenannte `}<em parentName="p">{`Mass Customization`}</em><sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-23"
      }}><a parentName="sup" {...{
          "href": "#ref-23",
          "className": "footnote-inline-link"
        }}>{`23`}</a></sup>{` auf Basis von bestehenden Komponenten ein individuell passendes Produkt anzubieten. Prahalad und Ramaswamy (2004) definieren den Begriff so, dass dabei die Vorteile einer Massenproduktion zu niedrigen Kosten mit dem gezielten Ausrichten auf die einzelnen KonsumentInnen kombiniert werden. Dank der sogenannten `}<em parentName="p">{`Mass Customization`}</em>{` ist es möglich, ohne grossen Mehraufwand auf die unterschiedlichen Wünsche der KundInnen einzugehen.`}</p>
    <p>{`Anstelle der bisherigen Massenproduktion können KundInnen also aus einer Anzahl vordefinierter Komponenten ihr eigenes Produkt zusammenstellen. Dies ist aber nicht eine Co-Creation im eigentlichen Sinne, vielmehr können die KundInnen ihr Produkt anhand von bestimmten Vorgaben selbst konfigurieren. So haben z. B. bei `}<em parentName="p">{`Local Motors`}</em>{` KundInnen bei der sogenannten `}<em parentName="p">{`Skin-Customization`}</em>{` die Möglichkeit, die Lackierung des Fahrzeugs selber zu definieren.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-24"
      }}><a parentName="sup" {...{
          "href": "#ref-24",
          "className": "footnote-inline-link"
        }}>{`24`}</a></sup></p>
    <p>{`Die AnbieterInnen gehen so aber eigentlich nur im Ansatz auf die Wünsche und Präferenzen ihrer KundInnen ein.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-25"
      }}><a parentName="sup" {...{
          "href": "#ref-25",
          "className": "footnote-inline-link"
        }}>{`25`}</a></sup>{` Primär wird die `}<em parentName="p">{`Mass Customization`}</em>{` dazu genutzt, unter Berücksichtigung der eigenen Lieferkette produzieren zu können. Dabei handelt es sich also nur um eine Spielart der Co-Creation, die den KonsumentInnen zwar ein individualisiertes aber dadurch noch nicht personalisiertes Produkt verschafft.`}</p>
    <p>{`Anders als bei einer `}<em parentName="p">{`Mass Customization`}</em>{`, bei der Produkte am Ende einer Produktionskette noch einen letzten Schliff durch den Kunden erhalten, definieren sich Produkte bei einer Co-Creation also über ein kontinuierliches Mitgestalten über das gesamte Angebot hinweg sowie über das damit verbundene, persönliche Gesamterlebnis. In dem Zusammenhang gewinnt auch das Firmennetzwerk zusätzlich an Bedeutung, da erst durch eine Vielzahl an unterschiedlichen Angeboten eine entsprechend flexibles und individuelles Umfeld für die KonsumentInnen gewährleistet werden kann.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-26"
      }}><a parentName="sup" {...{
          "href": "#ref-26",
          "className": "footnote-inline-link"
        }}>{`26`}</a></sup>{` Auf diese neuen Formen der Partnerschaften soll im nächsten Abschnitt eingegangen werden.`}</p>
    <h3 {...{
      "id": "die-gewichtung-von-partnerschaften"
    }}>{`Die Gewichtung von Partnerschaften`}</h3>
    <p>{`Um auf möglichst viele Fähigkeiten zurückgreifen und den KonsumentInnen dadurch ein möglichst breites Angebot bieten zu können, ist es wichtig, dass sich Firmen mit anderen Anbietern zusammenschliessen und sich dabei als Knotenpunkte innerhalb eines Netzwerkes begreifen, die den KonsumentInnen ein vollumfängliches Erlebnis über verschiedenste Kanäle hinweg ermöglichen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-27"
      }}><a parentName="sup" {...{
          "href": "#ref-27",
          "className": "footnote-inline-link"
        }}>{`27`}</a></sup></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b19789307c89262bf84520b5b7c0a5ef/1dd40/co-creation_1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd6bQig//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAERIP/aAAgBAQABBQITLj//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAaEAEAAwADAAAAAAAAAAAAAAABABARITFR/9oACAEBAAE/IePIapnUNUGV/9oADAMBAAIAAwAAABATD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAMAAQUBAAAAAAAAAAAAAAABEUEhMVFhcZH/2gAIAQEAAT8QiPZ8Eunx0QWGbqIZvkSSwf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Co-Creation",
            "title": "Co-Creation",
            "src": "/static/b19789307c89262bf84520b5b7c0a5ef/3d0fe/co-creation_1.jpg",
            "srcSet": ["/static/b19789307c89262bf84520b5b7c0a5ef/90185/co-creation_1.jpg 450w", "/static/b19789307c89262bf84520b5b7c0a5ef/7288a/co-creation_1.jpg 900w", "/static/b19789307c89262bf84520b5b7c0a5ef/3d0fe/co-creation_1.jpg 1800w", "/static/b19789307c89262bf84520b5b7c0a5ef/1dd40/co-creation_1.jpg 2400w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Das Erlebnis-Umfeld besteht aus einem Netzwerk aus Firmen sowie einer Community.`}<sup parentName="em" {...{
          "className": "footnote-inline",
          "id": "use-ref-fig-3"
        }}><a parentName="sup" {...{
            "href": "#ref-fig-3",
            "className": "footnote-inline-link"
          }}>{`Abb. 3`}</a></sup></em></p>
    <p>{`Dies hat Auswirkungen auf die gesamte Lieferkette. Da auf Grund der spezifischen Anforderungen der KundInnen nach jeweils unterschiedlichen Lösungen verlangt wird, läuft diese nicht mehr linear ab, sondern wird je nach individuellen Bedürfnissen immer wieder neu sortiert.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-28"
      }}><a parentName="sup" {...{
          "href": "#ref-28",
          "className": "footnote-inline-link"
        }}>{`28`}</a></sup></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5ebe651f715adad4300d19e5d6434f74/7a27c/co-creation_4.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.68054977092878%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7GolQ//8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQERL/2gAIAQEAAQUCnWI6X//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAABICEx/9oACAEBAAY/AhVsf//EABoQAQEAAgMAAAAAAAAAAAAAAAERACAhMUH/2gAIAQEAAT8hIqTrEg9JnNTT/9oADAMBAAIAAwAAABBjD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAAMAAwEAAAAAAAAAAAAAAAABESExQXH/2gAIAQEAAT8Qa0rwKE2TbUV10XCyzMlHHwUSiP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Co-Creation",
            "title": "Co-Creation",
            "src": "/static/5ebe651f715adad4300d19e5d6434f74/3d0fe/co-creation_4.jpg",
            "srcSet": ["/static/5ebe651f715adad4300d19e5d6434f74/90185/co-creation_4.jpg 450w", "/static/5ebe651f715adad4300d19e5d6434f74/7288a/co-creation_4.jpg 900w", "/static/5ebe651f715adad4300d19e5d6434f74/3d0fe/co-creation_4.jpg 1800w", "/static/5ebe651f715adad4300d19e5d6434f74/7a27c/co-creation_4.jpg 2401w"],
            "sizes": "(max-width: 1800px) 100vw, 1800px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Eine Firma erstellt in Zusammenarbeit mit Lieferanten Produkte und verkauft diese anschliessend an unterschiedliche Kundengruppen. `}<sup parentName="em" {...{
          "className": "footnote-inline",
          "id": "use-ref-fig-4"
        }}><a parentName="sup" {...{
            "href": "#ref-fig-4",
            "className": "footnote-inline-link"
          }}>{`Abb. 4`}</a></sup></em></p>
    <p>{`Da alle Standardbauteile der `}<em parentName="p">{`Local Motors`}</em>{` Fahrzeuge auch in vielen weiteren Autos verbaut sind, können diese nach dem Kauf auch in anderen Werkstätten gewartet werden. Weil zudem die technische Informationen und Baupläne offen liegen und über das Internet heruntergeladen werden können, wird hier über den Einbezug von Partnerfirmen erreicht, dass die KundInnen ein möglichst bequemes Nutzererlebnis haben.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-29"
      }}><a parentName="sup" {...{
          "href": "#ref-29",
          "className": "footnote-inline-link"
        }}>{`29`}</a></sup></p>
    <p>{`Auch daran wird deutlich, dass für die KundInnen Werte neu also nicht mehr primär aus der Konsumation eines Produktes am Ende einer Wertschöpfungskette bestehen, sondern über die Teilnahme an der gesamten Wertschöpfung.`}</p>
    <p>{`Um optimal auf die Interessen der KundInnen eingehen zu können, werden Angebote über die verschiedensten Bereiche hinweg kombiniert und dabei Allianzen mit den unterschiedlichsten Partnern gebildet. Eine Co-Creation intensiviert also nicht nur den Austausch zwischen Unternehmen und KundInnen, auch Firmen untereinander nähern sich darüber einander an. Dadurch lassen sich vielfältige Angebote über unterschiedliche Themenfeldern hinweg erstellen, worauf im nächsten Abschnitt eingegangen werden soll.`}</p>
    <h3 {...{
      "id": "relevante-inhalte-durch-strategische-partnerschaften"
    }}>{`Relevante Inhalte durch strategische Partnerschaften`}</h3>
    <p>{`Erst durch das gezielte Einbeziehen diverser Angebote von verschiedensten Partnern wird ein umfassendes Kundenerlebnis also überhaupt erst möglich. Die Zusammenarbeit zwischen Firmen dient aber auch oftmals dazu, für die NutzerInnen relevanten Inhalt zu generieren und diesen ein auf ihre Interessen abgestimmtes Angebot zu ermöglichen.`}</p>
    <p>{`Dies zeigt sich beispielsweise an der Zusammenarbeit zwischen `}<em parentName="p">{`Apple`}</em>{` und `}<em parentName="p">{`Nike`}</em>{` im Rahmen von `}<em parentName="p">{`Nike+`}</em>{`. Bei diesem Angebot können NutzerInnen über einen Sender im Schuh und ursprünglich in Kombination mit einem `}<em parentName="p">{`iPod`}</em>{` Daten über ihre absolvierten Läufe aufzeichnen. Gleichzeitig bekommen sie über eine Plattform im Internet Zugang zu den `}<em parentName="p">{`Online-Communities`}</em>{` von `}<em parentName="p">{`Apple`}</em>{` und `}<em parentName="p">{`Nike`}</em>{`. Das Angebot setzt dabei auf die bei Joggern beliebte Kombination aus Musik hören und Laufen und stellt somit die Schnittmenge der Interessensfelder der potentiellen NutzerInnen dar. Da sich beide Firmen in dem Zusammenhang gut ergänzen, wird dadurch für die NutzerInnen ein sinnstiftendes Angebot ermöglicht.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-30"
      }}><a parentName="sup" {...{
          "href": "#ref-30",
          "className": "footnote-inline-link"
        }}>{`30`}</a></sup></p>
    <p>{`Erst die in dem Zusammenhang häufig genannte Entstehung eines Ökosystems aus Partnerfirmen bietet den KonsumentInnen die Möglichkeit, ihre verschiedenen Interessen während der Nutzung eines Angebots miteinander zu verbinden. Gleichzeitig erweitert dieses Netzwerk aber auch die `}<em parentName="p">{`Community`}</em>{` insgesamt, wie der folgende Abschnitt verdeutlicht.`}</p>
    <h3 {...{
      "id": "der-austausch-mit-der-community"
    }}>{`Der Austausch mit der Community`}</h3>
    <p>{`Auch der Austausch in der `}<em parentName="p">{`Community`}</em>{` über Plattformen ermöglicht es, für die NutzerInnen relevanten Inhalt und somit ein sinnstiftendes Gesamterlebnis zu generieren. Dabei stehen für die NutzerInnen primär die Interaktionen mit Gleichgesinnten oder aber auch SpezialistInnen im Zentrum.`}</p>
    <p>{`Ein Beispiel für einen sinnstiftenden Austausch zwischen Laien und Fachleuten ist die `}<em parentName="p">{`Local Motors-Community`}</em>{`. Dort besteht für NutzerInnen die Möglichkeit sich über das Netzwerk mit IngenieurInnen und MechanikerInnen auszutauschen und dadurch mehr über ihr spezifisches Interessenfeld zu erfahren.`}</p>
    <p>{`In der `}<em parentName="p">{`Build it`}</em>{`-Phase haben die KonsumentInnen die Möglichkeit, ihr Fahrzeug mit MechanikerInnen von `}<em parentName="p">{`Local Motors`}</em>{` gemeinsam in einer `}<em parentName="p">{`Microfactory`}</em>{` zusammenzubauen. Dabei werden die Basisarbeiten von den `}<em parentName="p">{`Local Motors`}</em>{` MitarbeiterInnen übernommen und anschliessend wird das komplette Fahrzeug zusammen mit den KundInnen fertig montiert. Dadurch erhalten die KundInnen vertiefte Einblicke in den Fertigungsprozess und haben, dank dem direkten Zugang zu den MechanikerInnen die Möglichkeit, sich Fachwissen anzueignen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-31"
      }}><a parentName="sup" {...{
          "href": "#ref-31",
          "className": "footnote-inline-link"
        }}>{`31`}</a></sup></p>
    <p>{`Während früher also die Werbung um ein fertiges Produkt herum eine künstliche Erlebniswelt geschaffen hat, wird heute mittels Co-Creation anhand eines realen Erlebnisses einen Wert für die KonsumentInnen generiert.`}</p>
    <p>{`Ein weiteres Beispiel für einen sinnstiftenden Zugang zu einem für die NutzerInnen relevanten Inhalt bietet `}<em parentName="p">{`Nike+`}</em>{`. So haben NutzerInnen über die Website von `}<em parentName="p">{`Nike+`}</em>{` die Möglichkeit, Trainingstipps von Profis zu erhalten, sich über beliebte Laufstrecken zu informieren, sich gegenseitig anzuspornen, etc. Der Austausch innerhalb der `}<em parentName="p">{`Community`}</em>{` ist für sie also ein ganz elementarer Grund für die Nutzung des Angebots.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-32"
      }}><a parentName="sup" {...{
          "href": "#ref-32",
          "className": "footnote-inline-link"
        }}>{`32`}</a></sup></p>
    <p>{`Wie aufgezeigt wurde, verändert sich also durch eine Co-Creation grundlegend die Beziehung zwischen KundInnen und Unternehmen und damit geht auch eine neue  Wertedefinition in Bezug auf Produkte einher. Diese Entwicklungen bringen sowohl Vor- als auch Nachteile mit sich und im Folgenden sollen daher Chancen und Risiken thematisiert werden, die sich auf Grund der Co-Creation ergeben können.`}</p>
    <h2 {...{
      "id": "chancen-und-risiken-der-co-creation"
    }}>{`Chancen und Risiken der Co-Creation`}</h2>
    <h3 {...{
      "id": "das-abwägen-zwischen-vor--und-nachteilen"
    }}>{`Das Abwägen zwischen Vor- und Nachteilen`}</h3>
    <p>{`Gemäss Prahalad und Ramaswamy (2004) wird Co-Creation die Debatte um das Abwägen zwischen Vor- und Nachteilen einer Produktnutzung intensivieren. Eine grössere Transparenz und mehr Mitbestimmung für die KundInnen kann dazu führen, dass diese auch vermehrt eine Eigenverantwortung wahrnehmen müssen, ob und unter Einbezug welcher Risiken sie ein Angebot nutzen wollen.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-33"
      }}><a parentName="sup" {...{
          "href": "#ref-33",
          "className": "footnote-inline-link"
        }}>{`33`}</a></sup></p>
    <p>{`Da KonsumentInnen Zugang zu ehemals internem Wissen erhalten, steigen auch die Möglichkeiten, sich über allfällige Risiken bei der Nutzung eines Angebots detaillierter zu informieren. Zukünftig wird also nicht mehr das Management einer Firma für sie entscheiden, welche Risiken wie einzuschätzen und tragbar sind.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-34"
      }}><a parentName="sup" {...{
          "href": "#ref-34",
          "className": "footnote-inline-link"
        }}>{`34`}</a></sup>{` So müssen die NutzerInnen im Falle von `}<em parentName="p">{`Nike+`}</em>{` z. B. selber abwägen, ob sie bereit sind, ihre persönlichen Trainingsdaten gegen für sie relevanten Inhalt, wie beispielsweise Trainingstipps herzugeben und dafür der Firma Einsicht in ihre persönlichen Vorlieben gewähren.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-35"
      }}><a parentName="sup" {...{
          "href": "#ref-35",
          "className": "footnote-inline-link"
        }}>{`35`}</a></sup></p>
    <p>{`Aber auch sicherheitstechnische Aspekte spielen dabei eine Rolle. Da die Fahrzeuge bei `}<em parentName="p">{`Local Motors`}</em>{` nicht in einer fertigen, abgeschlossenen Form auf den Markt kommen und sich die Konstruktionen von den KundInnen beliebig abändern lassen, ist folglich auch keine Herstellergarantie im eigentlichen Sinne möglich. Auch hier liegt es in der Eigenverantwortung der NutzerInnen, was für sie  wichtiger ist, das ganz individuelle Fahrzeug oder eine Sicherheitsgarantie, wie sie bei herkömmlichen Automarken üblich ist.`}</p>
    <p>{`Da Firmen also mehr einen «Werkzeugkasten» als ein fertiges Produkt bereitstellen, aus dem sich die NutzerInnen dann bedienen können, liegt der Umgang mit einem Angebot stärker in deren eigenem Ermessen.`}</p>
    <p>{`Gleichzeitig müssen aber auch Firmen eine neue Risikoabwägung vornehmen, da die zunehmende Transparenz dazu führt, dass auch die Konkurrenz Einblicke in ehemals interne Firmenabläufe, wie z. B. Herstellungsprozesse, erhalten können. So macht die enge Zusammenarbeit mit anderen Firmen zwar ein umfassendes Kundenerlebnis erst möglich, gleichzeitig führt diese aber auch dazu, dass ehemals interne Informationen mit potentiellen Konkurrenten ausgetauscht werden müssen und diese somit auch einen vertieften Einblick in das eigene Geschäftsmodell erhalten.`}</p>
    <p>{`Im Falle der Baupläne von `}<em parentName="p">{`Local Motors`}</em>{`-Fahrzeugen wiegt diese Tatsache aber wohl weniger schwer, als der Umstand, dass durch diese Öffnung zufriedene KundInnen erreicht werden. Diese neue Transparenz bei Firmen ist ein Zeichen dafür, dass frühere Wettbewerbsvorteile, wie z. B. die Bauweise von Produkten, nicht mehr so gewichtig sind und dafür zunehmend versucht wird, diese über ein Kundenerlebnis und der damit verbundenen, gesteigerten Kundenloyalität aufzuwiegen.`}</p>
    <h3 {...{
      "id": "die-community-erhält-mehr-gewicht"
    }}>{`Die Community erhält mehr Gewicht`}</h3>
    <p>{`Wie bei Plattformen üblich, stellt sich auch die Frage nach der Kuratierung. Der vermehrte Austausch muss zunehmend betreut werden, dadurch wird `}<em parentName="p">{`Community-Management`}</em>{` immer wichtiger. Zwar wird bei `}<em parentName="p">{`Local Motors`}</em>{` versucht, eine Betreuung durch eine Verteilung auf unterschiedliche Schultern innerhalb der `}<em parentName="p">{`Community`}</em>{` zu bewerkstelligen (so dienen z. B. engagierte NutzerInnen anderen als Vorbilder), trotzdem wurden aber auch extra weitere `}<em parentName="p">{`Community`}</em>{`-ManagerInnen eingestellt.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-36"
      }}><a parentName="sup" {...{
          "href": "#ref-36",
          "className": "footnote-inline-link"
        }}>{`36`}</a></sup></p>
    <p>{`Der Dialog auf Augenhöhe mit der `}<em parentName="p">{`Community`}</em>{` führt zudem auch zu mehr Einfluss der KonsumentInnen. So haben diese dank dem Internet die Möglichkeiten, sich öffentlich zu äussern sowie Debatten anzustossen und damit direkten Einfluss auf eine Firma zu nehmen, wodurch sich auch ihre Macht gegenüber einer Firma vergrössert.`}</p>
    <h3 {...{
      "id": "die-demokratisierung-von-entscheidungen"
    }}>{`Die Demokratisierung von Entscheidungen`}</h3>
    <p>{`Durch die zunehmend demokratischen Verhältnisse z. B. bei Abstimmungen sollten Prozesse, welche unter Einbezug der `}<em parentName="p">{`Crowd`}</em>{` vollführt werden, klaren Regeln folgen und sich innerhalb eines vordefinierten Rahmens bewegen. Dabei sollten die Entscheidungsgewalten und Rollenverteilungen im Vorfeld genau definiert und auch kommuniziert werden. So ist das Mitbestimmungsrecht der `}<em parentName="p">{`Community`}</em>{` im Falle von `}<em parentName="p">{`Local Motors`}</em>{` beispielsweise limitiert. Die letzte Entscheidung darüber, welcher Wettbewerbs-Vorschlag effektiv für die Produktion verwendet wird, liegt bei Jay Rogers, dem CEO der Firma.`}<sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-37"
      }}><a parentName="sup" {...{
          "href": "#ref-37",
          "className": "footnote-inline-link"
        }}>{`37`}</a></sup></p>
    <h2 {...{
      "id": "fazit"
    }}>{`Fazit`}</h2>
    <p>{`Das Internet hat die Beziehung zwischen KundInnen und Unternehmen grundlegend verändert. Auf Grund von kontinuierlichen Feedbacks können Produktentwicklungen in enger Zusammenarbeit erstellt werden, wodurch Firmen ihre Strategie gezielter ausrichten und für NutzerInnen passgenauere Angebote ermöglicht werden.`}</p>
    <p>{`Dadurch verändert sich auch die Rolle der KundInnen, diese werden von passiven KonsumentInnen zu aktiven Mitwirkenden in der gesamten Wertschöpfungskette. Da ein Produkt nun direkt mitgestaltet und, dank dem vereinfachten Zugang über das Internet, auch den persönlichen Bedürfnissen angepasst werden kann, haben diese Produkte aber keine klar definierte Form mehr, sondern unterscheiden sich je nach Anforderungen der KundInnen.`}</p>
    <p>{`Mit der Individualisierung und dem direkten Zugang zu einer breiten Auswahl an Angeboten geht auch eine neue Wertedefinition einher. Der effektive Besitz eines materiellen Produktes wird weniger wichtig, vielmehr steht für den Kunden die Nutzung eines Angebots, also z. B. der Austausch innerhalb einer `}<em parentName="p">{`Community`}</em>{` oder der Zugang zu relevanten Inhalten sowie das damit verbundene Erlebnis im Zentrum.`}</p>
    <p>{`Über den Ausbau von Partnerschaften versuchen Firmen, den vielseitigen Ansprüchen der KundInnen gerecht zu werden und diesen ein personalisiertes Erlebnis zu ermöglichen. Der Wertschöpfungsprozess verschiebt sich dabei weg von Produkten und Services hin zu einem Co-Creation-Erlebnis unter Einbezug der gesamten `}<em parentName="p">{`Community`}</em>{`.`}</p>
    <p>{`Diese Form der Kollaboration setzt eine erhöhte Transparenz sowohl auf Firmenseite als auch bei den KundInnen voraus. Mit dem Austausch von Informationen und dem Zugang zu persönlichen Daten steigt aber auch das Risiko, da z. B. ehemals internes Wissen auch von der Konkurrenz genutzt werden kann oder aber die gesammelten Daten der KonsumentInnen auch in anderen Nutzungskontexten wiederverwendet werden können.`}</p>
    <p>{`In gewissem Sinne führt die Co-Creation über das Internet aber wieder zu einer Annäherung zwischen Firmen und ihren KundInnen und zu einer Form des direkten Austauschs, wie dieser früher in einem Laden um die Ecke gepflegt wurde, allerdings neu in Kombination mit den Skalierungsmöglichkeiten des Internets, indem sich nun eine grosse `}<em parentName="p">{`Community`}</em>{` sowie ein ganzes Netzwerk aus Firmen an einer gemeinsamen Zusammenarbeit beteiligen können.`}</p>
    <p>{`Abschliessend kann daher gesagt werden, dass sowohl der enge Austausch als auch die damit einhergehende grössere Transparenz und der unmittelbare Zugang zu Angeboten sowohl Chancen als auch Risiken beinhalten und zwar für die Firmen als auch die KundInnen selbst. Wenn diese aber bereit sind, sich trotz allfälliger Risiken zu öffnen und dadurch gegenseitig voneinander zu lernen führt dies im Idealfall dazu, dass beide Seiten von dieser neuen Nähe zwischen Unternehmen und Kunden profitieren können.`}</p>
    <h2 {...{
      "id": "anhang"
    }}>{`Anhang`}</h2>
    <h3 {...{
      "id": "endnoten"
    }}>{`Endnoten`}</h3>
    <div {...{
      "className": "ref-notes refnotes--default"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-1"
          }}><a parentName="span" {...{
              "id": "use-ref-1",
              "href": "#use-ref-1",
              "className": "footnote-ref-link"
            }}>{`↑ 1.`}</a>{` `}</span>{`Galloway 2017, S.`}{` `}{`27`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-2"
          }}><a parentName="span" {...{
              "id": "use-ref-2",
              "href": "#use-ref-2",
              "className": "footnote-ref-link"
            }}>{`↑ 2.`}</a>{` `}</span>{`Galloway 2017, S.`}{` `}{`27`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-3"
          }}><a parentName="span" {...{
              "id": "use-ref-3",
              "href": "#use-ref-3",
              "className": "footnote-ref-link"
            }}>{`↑ 3.`}</a>{` `}</span>{`Wikipedia 2020, Napster`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-4"
          }}><a parentName="span" {...{
              "id": "use-ref-4",
              "href": "#use-ref-4",
              "className": "footnote-ref-link"
            }}>{`↑ 4.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`36`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-5"
          }}><a parentName="span" {...{
              "id": "use-ref-5",
              "href": "#use-ref-5",
              "className": "footnote-ref-link"
            }}>{`↑ 5.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-6"
          }}><a parentName="span" {...{
              "id": "use-ref-6",
              "href": "#use-ref-6",
              "className": "footnote-ref-link"
            }}>{`↑ 6.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`2`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-7"
          }}><a parentName="span" {...{
              "id": "use-ref-7",
              "href": "#use-ref-7",
              "className": "footnote-ref-link"
            }}>{`↑ 7.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`4`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-8"
          }}><a parentName="span" {...{
              "id": "use-ref-8",
              "href": "#use-ref-8",
              "className": "footnote-ref-link"
            }}>{`↑ 8.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`134`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-9"
          }}><a parentName="span" {...{
              "id": "use-ref-9",
              "href": "#use-ref-9",
              "className": "footnote-ref-link"
            }}>{`↑ 9.`}</a>{` `}</span>{`Wikipedia 2020, Prosumer`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-10"
          }}><a parentName="span" {...{
              "id": "use-ref-10",
              "href": "#use-ref-10",
              "className": "footnote-ref-link"
            }}>{`↑ 10.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`10`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-11"
          }}><a parentName="span" {...{
              "id": "use-ref-11",
              "href": "#use-ref-11",
              "className": "footnote-ref-link"
            }}>{`↑ 11.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`27`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-12"
          }}><a parentName="span" {...{
              "id": "use-ref-12",
              "href": "#use-ref-12",
              "className": "footnote-ref-link"
            }}>{`↑ 12.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`28`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-13"
          }}><a parentName="span" {...{
              "id": "use-ref-13",
              "href": "#use-ref-13",
              "className": "footnote-ref-link"
            }}>{`↑ 13.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`31`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-14"
          }}><a parentName="span" {...{
              "id": "use-ref-14",
              "href": "#use-ref-14",
              "className": "footnote-ref-link"
            }}>{`↑ 14.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`30`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-15"
          }}><a parentName="span" {...{
              "id": "use-ref-15",
              "href": "#use-ref-15",
              "className": "footnote-ref-link"
            }}>{`↑ 15.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`30`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-16"
          }}><a parentName="span" {...{
              "id": "use-ref-16",
              "href": "#use-ref-16",
              "className": "footnote-ref-link"
            }}>{`↑ 16.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`32`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-17"
          }}><a parentName="span" {...{
              "id": "use-ref-17",
              "href": "#use-ref-17",
              "className": "footnote-ref-link"
            }}>{`↑ 17.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`129`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-18"
          }}><a parentName="span" {...{
              "id": "use-ref-18",
              "href": "#use-ref-18",
              "className": "footnote-ref-link"
            }}>{`↑ 18.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`47`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-19"
          }}><a parentName="span" {...{
              "id": "use-ref-19",
              "href": "#use-ref-19",
              "className": "footnote-ref-link"
            }}>{`↑ 19.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`59`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-20"
          }}><a parentName="span" {...{
              "id": "use-ref-20",
              "href": "#use-ref-20",
              "className": "footnote-ref-link"
            }}>{`↑ 20.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`116`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-21"
          }}><a parentName="span" {...{
              "id": "use-ref-21",
              "href": "#use-ref-21",
              "className": "footnote-ref-link"
            }}>{`↑ 21.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`116`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-22"
          }}><a parentName="span" {...{
              "id": "use-ref-22",
              "href": "#use-ref-22",
              "className": "footnote-ref-link"
            }}>{`↑ 22.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`49`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-23"
          }}><a parentName="span" {...{
              "id": "use-ref-23",
              "href": "#use-ref-23",
              "className": "footnote-ref-link"
            }}>{`↑ 23.`}</a>{` `}</span>{`Eichenauer 2017, S.`}{` `}{`32`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-24"
          }}><a parentName="span" {...{
              "id": "use-ref-24",
              "href": "#use-ref-24",
              "className": "footnote-ref-link"
            }}>{`↑ 24.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`47`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-25"
          }}><a parentName="span" {...{
              "id": "use-ref-25",
              "href": "#use-ref-25",
              "className": "footnote-ref-link"
            }}>{`↑ 25.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`89`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-26"
          }}><a parentName="span" {...{
              "id": "use-ref-26",
              "href": "#use-ref-26",
              "className": "footnote-ref-link"
            }}>{`↑ 26.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`97`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-27"
          }}><a parentName="span" {...{
              "id": "use-ref-27",
              "href": "#use-ref-27",
              "className": "footnote-ref-link"
            }}>{`↑ 27.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`49`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-28"
          }}><a parentName="span" {...{
              "id": "use-ref-28",
              "href": "#use-ref-28",
              "className": "footnote-ref-link"
            }}>{`↑ 28.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`97`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-29"
          }}><a parentName="span" {...{
              "id": "use-ref-29",
              "href": "#use-ref-29",
              "className": "footnote-ref-link"
            }}>{`↑ 29.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`46`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-30"
          }}><a parentName="span" {...{
              "id": "use-ref-30",
              "href": "#use-ref-30",
              "className": "footnote-ref-link"
            }}>{`↑ 30.`}</a>{` `}</span>{`Ramaswamy 2008`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-31"
          }}><a parentName="span" {...{
              "id": "use-ref-31",
              "href": "#use-ref-31",
              "className": "footnote-ref-link"
            }}>{`↑ 31.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`44`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-32"
          }}><a parentName="span" {...{
              "id": "use-ref-32",
              "href": "#use-ref-32",
              "className": "footnote-ref-link"
            }}>{`↑ 32.`}</a>{` `}</span>{`Ramaswamy 2008`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-33"
          }}><a parentName="span" {...{
              "id": "use-ref-33",
              "href": "#use-ref-33",
              "className": "footnote-ref-link"
            }}>{`↑ 33.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`32`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-34"
          }}><a parentName="span" {...{
              "id": "use-ref-34",
              "href": "#use-ref-34",
              "className": "footnote-ref-link"
            }}>{`↑ 34.`}</a>{` `}</span>{`Prahalad/Ramaswamy 2004, S.`}{` `}{`27`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-35"
          }}><a parentName="span" {...{
              "id": "use-ref-35",
              "href": "#use-ref-35",
              "className": "footnote-ref-link"
            }}>{`↑ 35.`}</a>{` `}</span>{`Ramaswamy 2008`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-36"
          }}><a parentName="span" {...{
              "id": "use-ref-36",
              "href": "#use-ref-36",
              "className": "footnote-ref-link"
            }}>{`↑ 36.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`36`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-37"
          }}><a parentName="span" {...{
              "id": "use-ref-37",
              "href": "#use-ref-37",
              "className": "footnote-ref-link"
            }}>{`↑ 37.`}</a>{` `}</span>{`Buhse/Reppesgaard/Lessmann/Henkel/Lange 2011, S.`}{` `}{`17`}</p>
      </li>
    </ul>
    <undefined></undefined>
    <h3 {...{
      "id": "literaturverzeichnis"
    }}>{`Literaturverzeichnis`}</h3>
    <p>{`Galloway, S. (2018). the four. Die geheime DNA von Amazon, Apple, Facebook und Google.
Deutschland: Plassen.`}</p>
    <p>{`Prahalad, C. K./Ramaswamy V. (2004). The Future of Competition. Co-Creating Unique Value with Customers.
USA: Harvard Business School Press.`}</p>
    <p>{`Eichenauer S. (2017). Crowdsourcing als neuer Ansatz der Interaktion zwischen Marken und ihren Kunden.
Deutschland: Verlag Dr. Kovač.`}</p>
    <p>{`Buhse W./Reppesgaard L./Lessmann U./Henkel S./Lange J. O. (2011). Der Case Local Motors: Co-Creation und Collaboration in der Automotive-Industrie.
Deutschland/Schweiz: doubleYUU/Forschungsstelle für Customer Insight der Universität St. Gallen/T-Systems International GmbH.`}</p>
    <p>{`Ramaswamy V. (2008). Co-creating value through customers’ experiences: the Nike case.
England: Emerald Group Publishing Limited.`}</p>
    <h3 {...{
      "id": "quellenverzeichnis"
    }}>{`Quellenverzeichnis`}</h3>
    <p>{`Wikipedia (2020). Napster (Online-Musikdienst).
`}<a parentName="p" {...{
        "href": "https://web.archive.org/web/20200221093043/https://de.wikipedia.org/wiki/Napster_(Online-Musikdienst)"
      }}>{`https://web.archive.org/web/20200221093043/https://de.wikipedia.org/wiki/Napster_(Online-Musikdienst)`}</a>{`,
zuletzt besucht am Sonntag, 6. Januar 2020; 10:14 Uhr.`}</p>
    <p>{`Wikipedia (2020). Prosumer.
`}<a parentName="p" {...{
        "href": "https://web.archive.org/web/20200221093331/https://de.wikipedia.org/wiki/Prosumer"
      }}>{`https://web.archive.org/web/20200221093331/https://de.wikipedia.org/wiki/Prosumer`}</a>{`,
zuletzt besucht am Sonntag, 5. Januar 2020; 16:46 Uhr.`}</p>
    <h3 {...{
      "id": "abbildungsverzeichnis"
    }}>{`Abbildungsverzeichnis`}</h3>
    <div {...{
      "className": "ref-notes refnotes--fig"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-fig-1"
          }}><a parentName="span" {...{
              "id": "use-ref-fig-1",
              "href": "#use-ref-fig-1",
              "className": "footnote-ref-link"
            }}>{`↑ Abb. 1`}</a>{` – `}</span>{`Abb. 1: Prahalad, C. K./Ramaswamy V. (2004). The Future of Competition. Co-Creating Unique Value with Customers. USA: Harvard Business School Press. (97) Nachbau der Originalgrafik`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-fig-2"
          }}><a parentName="span" {...{
              "id": "use-ref-fig-2",
              "href": "#use-ref-fig-2",
              "className": "footnote-ref-link"
            }}>{`↑ Abb. 2`}</a>{` – `}</span>{`Abb. 2: Prahalad, C. K./Ramaswamy V. (2004). The Future of Competition. Co-Creating Unique Value with Customers. USA: Harvard Business School Press. (96) Nachbau der Originalgrafik`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-fig-3"
          }}><a parentName="span" {...{
              "id": "use-ref-fig-3",
              "href": "#use-ref-fig-3",
              "className": "footnote-ref-link"
            }}>{`↑ Abb. 3`}</a>{` – `}</span>{`Abb. 3: Prahalad, C. K./Ramaswamy V. (2004). The Future of Competition. Co-Creating Unique Value with Customers. USA: Harvard Business School Press. (120) Nachbau der Originalgrafik`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-fig-4"
          }}><a parentName="span" {...{
              "id": "use-ref-fig-4",
              "href": "#use-ref-fig-4",
              "className": "footnote-ref-link"
            }}>{`↑ Abb. 4`}</a>{` – `}</span>{`Abb. 4: Prahalad, C. K./Ramaswamy V. (2004). The Future of Competition. Co-Creating Unique Value with Customers. USA: Harvard Business School Press. (122) Nachbau der Originalgrafik`}</p>
      </li>
    </ul>
    <undefined></undefined>
    <Grid mdxType="Grid">
      <hr></hr>
      <Column start="1" end="5" mdxType="Column">
        <h4 {...{
          "id": "mentoring-durch"
        }}>{`Mentoring durch`}</h4>
        <p>{`Ulrike Felsing `}<br />{`
HFIAD 2017 `}<br />{`
Schule für Gestaltung Zürich `}<br />{`
`}<a parentName="p" {...{
            "href": "mailto:ulrike.felsing@sfgz.ch"
          }}>{`ulrike.felsing@sfgz.ch`}</a></p>
      </Column>
      <Column start="5" end="13" mdxType="Column">
        <h4 {...{
          "id": "vorgelegt-von"
        }}>{`Vorgelegt von`}</h4>
        <p>{`Wolfgang Schoeck `}<br />{`
HFIAD 2017 `}<br />{`
Schule für Gestaltung Zürich `}<br />{`
`}<a parentName="p" {...{
            "href": "mailto:wolfgang.schoeck@sfgz.ch"
          }}>{`wolfgang.schoeck@sfgz.ch`}</a></p>
      </Column>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      